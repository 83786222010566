import { Stack } from "@mui/material";
import { convertToGeoLocation, convertToGoogleMapsLocation } from "@src/appV2/Location/utils";

import type { WorkplaceProfile } from "../types";
import { WorkplaceCheckInInstructionsCard } from "./CheckInInstructions";
import { WorkplaceHighlightsAndReviewsCard } from "./HighlightsAndReviewsCard";
import { WorkplaceImportantInformationCard } from "./ImportantInformationCard";
import { WorkplaceProfileOverviewSection } from "./OverviewSection";
import { WorkplacePoliciesCard } from "./PoliciesCard";

interface WorkplaceProfileModalContentProps {
  workplaceProfile: WorkplaceProfile;
}

export function WorkplaceProfileModalContent(props: WorkplaceProfileModalContentProps) {
  const { workplaceProfile } = props;

  const {
    userId: workplaceId,
    name,
    type,
    phone,
    geoLocation,
    lateCancellation,
    requiresLunchBreak,
    providesRestBreaks,
  } = workplaceProfile;

  return (
    <>
      <WorkplaceProfileOverviewSection
        name={name}
        type={String(type)}
        workplaceId={String(workplaceId)}
        phoneNumber={phone}
        location={convertToGoogleMapsLocation(convertToGeoLocation(geoLocation.coordinates))}
      />

      <Stack spacing={5}>
        <WorkplaceHighlightsAndReviewsCard workplaceId={String(workplaceId)} />
        <WorkplaceImportantInformationCard workplaceId={String(workplaceId)} />
        <WorkplacePoliciesCard
          workplaceId={String(workplaceId)}
          lateCancellation={lateCancellation}
          requiresLunchBreak={requiresLunchBreak}
          providesRestBreaks={providesRestBreaks}
        />
        <WorkplaceCheckInInstructionsCard workplaceId={String(workplaceId)} />
      </Stack>
    </>
  );
}
