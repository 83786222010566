import { Stack } from "@mui/material";
import { type Location } from "@src/appV2/lib/GoogleMaps";
import { useIsOnCallShift } from "@src/appV2/Shifts/Shift/useIsOnCallShift";

import { useDistanceToWorkplace } from "../useDistanceToWorkplace";
import { WorkplaceProfileActions } from "./Actions";
import { WorkplaceProfileHeading } from "./Heading";
import { WorkplaceProfileLocationAndGallery } from "./LocationAndGallery";

interface WorkplaceProfileOverviewSectionProps {
  name: string;
  type: string;
  workplaceId: string;
  phoneNumber?: string;
  location: Location;
}

export function WorkplaceProfileOverviewSection(props: WorkplaceProfileOverviewSectionProps) {
  const { name, type, workplaceId, phoneNumber, location } = props;

  const isOnCallShift = useIsOnCallShift(workplaceId);

  const {
    formattedDistance,
    isLoading: distanceIsLoading,
    isError: distanceIsError,
  } = useDistanceToWorkplace({
    workplaceGeoLocation: {
      latitude: location.lat,
      longitude: location.lng,
    },
  });

  return (
    <>
      <Stack spacing={8} paddingBottom={isOnCallShift ? 5 : 0} data-testid="overview-section-stack">
        <WorkplaceProfileHeading
          name={name}
          type={type}
          distanceIsLoading={distanceIsLoading}
          distanceIsError={distanceIsError}
          distanceText={formattedDistance ? `${formattedDistance} from home` : undefined}
        />

        <WorkplaceProfileActions workplaceId={workplaceId} phoneNumber={phoneNumber} />
      </Stack>

      {!isOnCallShift && (
        <WorkplaceProfileLocationAndGallery
          workplaceId={workplaceId}
          workplaceName={name}
          location={location}
          scrollViewOuterPaddingCompensation={7}
          data-testid="workplace-location-gallery"
          scrollViewSx={(theme) => ({
            height: "max(13.5rem, 25vh)",
            boxSizing: "content-box",

            // Add padding instead of Stack spacing to ensure box shadows are fully visible
            py: 9,
            // Reduce the total spacing by using negative margin since the padding is larger
            // than needed (to make the shadows fully visible)
            my: `calc(-1 * ${theme.spacing(3)})`,

            // Style all direct children with elevation and rounded corners
            "& > *": {
              boxShadow: theme.shadows[3],
              borderRadius: theme.borderRadius?.medium,
            },
          })}
        />
      )}
    </>
  );
}
