import { isDefined } from "@clipboard-health/util-ts";
import { Stack } from "@mui/material";
import { DeprecatedGlobalAppV1Paths } from "@src/appV2/App/paths";
import { generatePath } from "react-router-dom";

import { Button } from "../../components/Button";

interface WorkplaceProfileActionsProps {
  workplaceId: string;
  phoneNumber?: string;
  displayChatButton?: boolean;
}

export function WorkplaceProfileActions(props: WorkplaceProfileActionsProps) {
  const { workplaceId, phoneNumber, displayChatButton = true } = props;

  return (
    <Stack direction="row" spacing={3}>
      {displayChatButton && (
        <Button
          invert
          startIconType="text-bubble"
          size="small"
          variant="contained"
          href={generatePath(DeprecatedGlobalAppV1Paths.CHAT, {
            facilityId: workplaceId,
          })}
          sx={(theme) => ({ boxShadow: theme.shadows[2] })}
        >
          Chat with workplace
        </Button>
      )}
      {isDefined(phoneNumber) && (
        <Button
          invert
          startIconType="phone"
          size="small"
          variant="contained"
          href={`tel:+1${phoneNumber}`}
          sx={(theme) => ({ boxShadow: theme.shadows[2] })}
        >
          Call
        </Button>
      )}
    </Stack>
  );
}
