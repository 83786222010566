import { Text, Title } from "@clipboard-health/ui-react";
import { Box } from "@mui/material";

import { DistanceText } from "../../components/DistanceText";
import { DotSeparatedList } from "../../components/DotSeparatedList";

interface WorkplaceProfileHeadingProps {
  name: string;
  type: string;
  distanceText?: string;
  distanceIsLoading: boolean;
  distanceIsError: boolean;
}
export function WorkplaceProfileHeading(props: WorkplaceProfileHeadingProps) {
  const { name, type, distanceText, distanceIsLoading, distanceIsError } = props;

  return (
    <Box>
      <Title component="h3" variant="h4" sx={{ marginBottom: 1 }}>
        {name}
      </Title>

      <DotSeparatedList>
        <Text semibold variant="caption" color={(theme) => theme.palette.text.tertiary}>
          {type}
        </Text>

        {distanceText && (
          <DistanceText
            semibold
            isError={distanceIsError}
            isLoading={distanceIsLoading}
            formattedDistance={distanceText}
            variant="caption"
            color={(theme) => theme.palette.text.tertiary}
          />
        )}
      </DotSeparatedList>
    </Box>
  );
}
