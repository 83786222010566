import { Box, Skeleton, Stack } from "@mui/material";

import { TextSkeleton } from "../../components/TextSkeleton";

export function WorkplaceProfileModalContentSkeleton() {
  return (
    <Stack spacing={8}>
      <Stack spacing={8}>
        <Box>
          <TextSkeleton variant="h2" width="75%" sx={{ marginBottom: 4 }} />
          <TextSkeleton variant="h3" width="75%" />
        </Box>
        <Stack direction="row" spacing={3}>
          <Skeleton
            variant="circular"
            sx={(theme) => ({
              width: theme.size?.iconButton.large.size,
              height: theme.size?.iconButton.large.size,
            })}
          />
          <Skeleton
            variant="circular"
            sx={(theme) => ({
              width: theme.size?.iconButton.large.size,
              height: theme.size?.iconButton.large.size,
            })}
          />
          <Skeleton
            variant="circular"
            sx={(theme) => ({
              width: theme.size?.iconButton.large.size,
              height: theme.size?.iconButton.large.size,
            })}
          />
        </Stack>
      </Stack>
      <Skeleton
        variant="rounded"
        sx={{
          width: "100%",
          height: "100%",
          aspectRatio: "1.8/1",
        }}
      />
      <Skeleton
        variant="rounded"
        sx={{
          width: "100%",
          height: "100%",
          aspectRatio: "1.8/1",
        }}
      />
    </Stack>
  );
}
